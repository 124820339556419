import * as React from "react";
import * as style from "./navDesktop.module.scss";
import { v4 as uuidv4 } from "uuid";
import { navigate } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import Link from "components/Link";
import Dropdown from "components/Dropdown";
import { navOptions, searchEverythingPath } from "./config.js";
import logoCitius from "images/corporative/logo_citius_tagline_horizontal.svg";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const NavSearch = ({ searchValue }) => {
  const { t } = useTranslation();
  const { language, defaultLanguage } = useI18next();

  function doSearch(event) {
    if (
      event.type === "click" ||
      (event.type === "keypress" && event.key === "Enter")
    ) {
      const value = document.getElementById("GlobalSearchInput").value;
      let path = searchEverythingPath;
      if (language !== defaultLanguage) {
        path = `/${language}${path}`;
      }
      navigate(path + "?search=" + encodeURI(value));
    }
  }
  if( window.location.pathname.includes("/search") === false ){
    return (
      <div className={style.NavSearch}>
        <button type="button" onClick={(event) => doSearch(event)} aria-label={t("search")}>
          <SearchIcon />
        </button>
        <input
          id="GlobalSearchInput"
          type="search"
          placeholder={`${t("search")}...`}
          defaultValue={searchValue || ""}
          onKeyPress={(event) => doSearch(event)}
        />
      </div>
    );
  }
  else{
    return <></>;
  }
};

const LanguageSelector = () => {
  const { language, languages, changeLanguage } = useI18next();
  return (
    <div className={style.LanguageSelector}>
      <Dropdown
        variant="topBar"
        trigger={
          <>
            {language}
            <KeyboardArrowDownIcon />
          </>
        }
        menuItems={languages.map((lng) => {
          if (lng !== language) {
            return (
              <button
                key={lng}
                onClick={(e) => {
                  e.preventDefault();
                  changeLanguage(lng);
                }}
              >
                {lng}
              </button>
            );
          }
          return null;
        })}
      />
    </div>
  );
};

const NavMenu = () => {
  const { t } = useTranslation();
  return (
    <div className={style.NavMenu}>
      {navOptions.map((option) => {
        if (option.submenu) {
          return (
            <Dropdown
              key={uuidv4()}
              variant="navMenu"
              trigger={t(`${option.name}`)}
              menuItems={
                <>
                  {option.submenu.map((suboption) => (
                    <Link to={suboption.link} key={uuidv4()}>
                      {t(`${suboption.name}`)}
                    </Link>
                  ))}
                </>
              }
            />
          );
        }
        return (
          <Link
            to={option.link}
            key={option.link}
            className={style.NavMenu_link}
          >
            {t(`${option.name}`)}
          </Link>
        );
      })}
    </div>
  );
};

const TopBar = () => (
  <div className={style.NavTopBar}>
    <NavSearch />
    <LanguageSelector />
  </div>
);

const NavBar = () => (
  <nav className={style.NavBar}>
    <Link to="/" className={style.NavBar_logo}>
      <img src={logoCitius} alt="Citius" />
    </Link>
    <NavMenu />
  </nav>
);

const NavDesktop = () => {
  return (
    <div className={style.NavDesktop}>
      <TopBar />
      <NavBar />
    </div>
  );
};

export default NavDesktop;
